<template>
  <div>
    <b-button id="invite-button-202012301559"
              class="my-auto ml-4 px-4"
              variant="primary"
              @click="resetValues(true)"
    >
      <img src="../../../../public/img/icons/light-plus-icon.svg" />
      {{staticText.inviteNewUserBtn}}
    </b-button>

    <b-modal id="invite-modal-202012301554"
             v-model="inviteModal"
             :ok-title="staticText.okTitle"
             :cancel-title="staticText.cancelTitle"
             :ok-disabled="okDisabled"
             @ok="handleInvite"
    >
      <template v-slot:modal-header-close>
        <img src="../../../../public/img/icons/close-icon.svg"/>
      </template>
      <template v-slot:modal-title>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mr-2'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g opacity=".8" fill="#2B2727"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.795 8.373a4.11 4.11 0 001.569.31c2.216 0 4.019-1.746 4.019-3.892S13.58.9 11.364.9a4.11 4.11 0 00-1.569.309c1.44.592 2.45 1.975 2.45 3.582 0 1.607-1.01 2.99-2.45 3.582zM12.612 9.575a5.155 5.155 0 001.97-.972c1.519 1.004 2.518 2.694 2.518 4.605v1.965c0 1.062-.893 1.927-1.99 1.927h-2.707l-.006-.045c.894-.189 1.565-.96 1.565-1.882v-1.965c0-1.566-.378-2.716-1.35-3.633z"/><path d="M6.658 8.682c2.216 0 4.019-1.745 4.019-3.891S8.874.9 6.657.9C4.443.9 2.64 2.646 2.64 4.791c0 2.146 1.803 3.891 4.019 3.891z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6.658 9.73a5.18 5.18 0 01-3.24-1.128C1.899 9.607.9 11.297.9 13.208v1.964c0 1.063.893 1.927 1.99 1.927H10.404c1.097 0 1.99-.864 1.99-1.927v-1.964c0-1.911-.999-3.6-2.518-4.606A5.18 5.18 0 016.658 9.73z"/></g></svg>
        <span>{{ staticText.inviteNewUserTitle }}</span>
      </template>
      <b-form-group :id="'input-group-' + index"
                    :label-for="'input-group-' + index"
                    v-for="(item, index) in inputFields"
                    :key="index"
      >
        <div class="input-wrapper">
          <b-form-input :id="'input-id-' + index"
                        :name="'input-name-' + index"
                        v-model="$data[item.modelKey]"
                        :state="validateState(item.validationKey)"
                        aria-describedby="'input-id-' + index + '-live-feedback'"
                        :placeholder="item.placeholder"
          ></b-form-input>
          <span class="input-label">{{ item.placeholder }}</span>
        </div>

        <b-form-invalid-feedback :id="'input-id-' + index + '-live-feedback'">
          {{item.invalidText}}
        </b-form-invalid-feedback>

      </b-form-group>

      <!--team to add User-->
      <div v-if="multipleTeamsExist">
        <label for="parent-teams">
          {{ staticText.teamsToAddUserLabel }}
        </label>
        <treeselect id="parent-teams"
                    class="mb-3"
                    v-model="teamsToAddUser"
                    :options="teamTree"
                    :normalizer="normalizer"
                    :multiple="true"
                    :flat="true"
                    :placeholder="staticText.selectTeamPlaceholder"
                    search-nested
        ></treeselect>
      </div>

      <div v-if="domainError">
        <b-alert :key="123"
                 variant="warning"
                 show
        >
          {{ domainError }}
        </b-alert>
      </div>

    </b-modal>

  </div>

</template>

<script>
import Treeselect from "@riophae/vue-treeselect"
import { treeSelectUtils } from "@/apps/user/TeamManagementComponents/TeamManagementUtils"
import axios from "axios"
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "UserCreation",
  components: { Treeselect },
  data () {
    return {
      axios,
      emailInputErrors: [],
      userDomain: null,
      enteredDomain: null,
      first_name: null,
      last_name: null,
      email: null,
      teamsToAddUser: [],
      staticTextDefault: {
        okTitle: "Invite",
        cancelTitle: "Cancel",
        inviteNewUserBtn: "Invite New User",
        inviteNewUserTitle: "Invite a New User",
        emailLabel: "E-Mail",
        firstNameLabel: "First name",
        lastNameLabel: "Last name",
        teamsToAddUserLabel: "Select Teams To Add User",
        selectTeamPlaceholder: "Select teams",
        userAlreadyExistsMessage: "The user already exists in team",
        userAddedInvitedMessage: "The user is invited and added to team",
        userRecoveredInvitedMessage: "The user was recovered and invited again to team",
        emailValidityMessage: "This field is required and must contain a valid email address.",
        requiredFieldMessage: "This field is required.",
        domainErrorWarning: "The email address you entered is from a different domain. Are you sure you want to invite this user?"
      },
      inviteModal: false,
      domainError: null,
      submitted: false,
      inviteSuccessMessage: null

    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user",
      teamTree: "teams/getTeamTreeForUser",
      multipleTeamsExist: "teams/multipleTeamsExist"
    }),
    inputFields () {
      return [
        {
          modelKey: "email",
          validationKey: "email",
          placeholder: this.staticText.emailLabel,
          label: this.staticText.emailLabel,
          invalidText: this.staticText.emailValidityMessage
        },
        {
          modelKey: "first_name",
          validationKey: "",
          placeholder: this.staticText.firstNameLabel,
          label: this.staticText.firstNameLabel
        },
        {
          modelKey: "last_name",
          validationKey: "last_name",
          placeholder: this.staticText.lastNameLabel,
          label: this.staticText.lastNameLabel,
          invalidText: this.staticText.requiredFieldMessage
        }
      ]
    },
    okDisabled () {
      return this.emailInputErrors.length > 0 ||
        !this.email || !this.first_name || !this.last_name
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    currentUserEmail () {
      return this.currentUser.email
    }
  },
  mounted () {
    this.getTeamTreeForCurrentUser().then(() => {
      this.teamsToAddUser = [this.teamTree[0].id]
    })
  },
  methods: {
    ...treeSelectUtils.methods,
    ...mapActions({
      getTeamTreeForCurrentUser: "teams/getTeamTreeForUser"
    }),
    ...mapMutations({
      setSuccessMessage: "teams/setSuccessMessage",
      setErrorMessage: "teams/setErrorMessage",
      setTeamsToReload: "teams/setTeamsToReload"
    }),
    validateState (name) {
      if (this.email && this.isEmailValidOrEmpty(this.email) && !this.checkDomain(this.email)) {
        this.domainError = this.staticText.domainErrorWarning
      } else {
        this.domainError = null
      }
      if (name === "email" && this.submitted) {
        return !!this.email && this.isEmailValidOrEmpty(this.email)
      }
      if (name === "last_name" && this.submitted) {
        return !!this.last_name && this.last_name.length > 0
      }
    },
    onInvite () {
      this.setErrorMessage(null)
      this.setSuccessMessage(null)
      this.inviteModal = false
      const userData = {
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        teams: this.teamsToAddUser
      }
      this.axios
        .post("/api/users/invite", userData)
        .then(response => {
          this.handleInviteResponse(response.data)
          this.setTeamsToReload(userData.teams)
        })
        .catch(error => {
          this.handleInviteResponse(error.response.data)
        })
    },
    handleInviteResponse (data) {
      let messageToDisplay = ""
      data.forEach(message => {
        if (!message.invited && !message.created && !message.added) {
          messageToDisplay += `${this.staticText.userAlreadyExistsMessage}: ${message.team_name}.<br/>`
        } else if (message.invited && message.created && message.added) {
          messageToDisplay += `${this.staticText.userAddedInvitedMessage}: ${message.team_name}.<br/>`
        } else if (message.invited && !message.created && !message.added) {
          messageToDisplay += `${this.staticText.userRecoveredInvitedMessage}: ${message.team_name}.<br/>`
        }
      })
      this.inviteSuccessMessage = messageToDisplay
      this.setSuccessMessage(messageToDisplay)
    },
    resetValues (openInviteModal = false) {
      this.emailInputErrors = []
      this.first_name = null
      this.last_name = null
      this.email = null
      this.inviteModal = openInviteModal
      this.submitted = false
    },
    checkForm () {
      for (const i in this.inputFields) {
        const iField = this.inputFields[i]
        if (iField.validationKey && !this.validateState(iField.validationKey)) {
          return false
        }
      }
      return true
    },
    isEmailValidOrEmpty (email) {
      const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return !email || regExp.test(email)
    },
    checkDomain (email) {
      this.userDomain = this.currentUserEmail.substring(this.currentUserEmail.lastIndexOf("@"))
      this.enteredDomain = email.substring(email.lastIndexOf("@"))
      return this.userDomain === this.enteredDomain
    },
    handleInvite (inviteEvent) {
      inviteEvent.preventDefault()
      this.submitted = true
      if (this.checkForm()) {
        this.onInvite()
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
